
import './../../scss/blocks/_tariff-short-block.scss'
import { selectTariff } from './../components/tariffSelector.js'

const tariffSlider = () => {
    if (window.innerWidth < 1280) {
        // eslint-disable-next-line no-undef
        return new Swiper('.js-tariff-slider', {
            initialSlide: 0,
            loop: false,
            width: 280,
            slidesPerView: 'auto',
        })
    }
}

document.addEventListener(
    'DOMContentLoaded',
    () => {
        selectTariff()
        tariffSlider()
    },
)

window.addEventListener('resize', tariffSlider)
